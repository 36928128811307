import request from '@/utils/request';
export var defaultStateData = {
  id: 0,
  enabled: true,
  countryId: undefined,
  name: '',
  country: {
    id: undefined
  }
};
export var getStates = function getStates(params) {
  return request({
    url: '/states',
    method: 'get',
    params: params
  });
};
export var getStateById = function getStateById(id) {
  return request({
    url: "/states/".concat(id),
    method: 'get'
  });
};
export var updateState = function updateState(id, data) {
  return request({
    url: "/states/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteState = function deleteState(id) {
  return request({
    url: "/states/".concat(id),
    method: 'delete'
  });
};
export var createState = function createState(data) {
  return request({
    url: '/states/',
    method: 'post',
    data: data
  });
};